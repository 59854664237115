import React, { useEffect, useLayoutEffect } from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import SEO from "~/components/seo";
import Content from "~/components/Page/Content/Content";
import { DeriveHeaderClasses } from "~/components/Navigation";
import { Container } from "~/components/.base/containers";
import { css } from "styled-components";
import { BodyText, Heading, Subheading } from "components/.base/headings";

const LS_RELOAD_FLAG = 'cml-termly-reload-flag';

export const TERMLY_PAGE_TYPES = {
  DISCLAIMER: 'disclaimer'
}

const TermlyPage = ({ data, location, pageType }) => {
  const {
    contentfulPage: { title, termlyScriptUrl, termlyId }
  } = data;

  useLayoutEffect(() => {
    const reloadFlag = window.localStorage.getItem(LS_RELOAD_FLAG);

    if (!reloadFlag) {
      window.localStorage.setItem(LS_RELOAD_FLAG, 'true');
      window.location.reload();
    };

    const script = document.createElement("script");
    script.src = termlyScriptUrl;
    script.id = 'termly-jssdk';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
      window.localStorage.removeItem(LS_RELOAD_FLAG);
    };
  }, [location.pathname]);

  return (
    <>
      <SEO title={title} />
      <DeriveHeaderClasses location={location} />
      <Container
        css={css`
          width: 970px;
          max-width: 100%;
          min-height: 100vh;
          padding-bottom: 120px;
        `}
      >
        <div
          name="termly-embed"
          data-id={termlyId}
          data-type="iframe"
        ></div>

        {pageType === TERMLY_PAGE_TYPES.DISCLAIMER && (
          <div
            css={css`
              padding-top: 20px;
              padding-bottom: 0;
            `}
          >
            <Subheading
              css={css`
                font-family: Arial !important;
                font-size: 19px !important;
                color: #000000 !important;
                font-weight: bold !important;
                letter-spacing: 0 !important;
                padding-bottom: 10px !important;
              `}
            >
              LOBSTER SOURCING DISCLAIMER
            </Subheading>
            <BodyText
              css={css`
                color: #595959 !important;
                font-size: 14px !important;
                font-family: Arial !important;
                line-height: 1.5 !important;
              `}
            >
              Cousins Maine Lobster strives to source only Maine lobster; a wild-caught product.
              Along with providing the best product possible to our valued customers,
              our priority is supporting the Maine lobster industry, and the related supply
              chain businesses that bolster the economies of Maine’s iconic coastal and island
              lobstering communities. When Maine lobster is not available, due to the seasonality
              of the product and/or supply and demand constraints, we may source lobster from
              Canada that meets our high standards and matches our proprietary specs for lobster
              meat– in regards to taste, tenderness, color, and overall eating experience.
              All of our lobster is the same species, Homarus Americanus, which is a North American
              lobster species that is commonly known as “Maine lobster” and “Atlantic lobster.”
            </BodyText>
          </div>
        )}
      </Container>
    </>
  );
};

export default TermlyPage;
